import { Component, OnInit } from '@angular/core';
import { UserService } from "../user.service";
import { Router } from "@angular/router";
import { Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
declare var $: any;
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-checkoutpage',
  templateUrl: './checkoutpage.component.html',
  styleUrls: ['./checkoutpage.component.css']
})
export class CheckoutpageComponent implements OnInit {
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  public showlogin = true;
  public showregistration = false; showloginfrom = false;
  public accountId;
  public showforgetpassword = false;
  public showpayment = false;
  public productdetails: any = {};
  isValidFormSubmitted = false;
  hidesummary = true;
  validateEmail = true; confirmcodeotp = false; enetrnewotp = false;

  studentsubscriptionId = '';
  userRole = "student"; showstudentform = false;
  choosestudent = false;
  showstudentslist = false;
  public studentlist;

  public loginerror = false;
  emailPattern = "/^[^\s@]+@[^\s@]+\.[^\s@]+$/";
  passwordPattern = '^[0-9]{3,}$';
  minPattern = ".{5,}";
  twominPattern = ".{2,}";
  send; isDisabled = true; isDisabled1 = true; otpid; resend; show;
  public userinfo;

  constructor(private spinner: NgxSpinnerService,private router: Router, private userservice: UserService, private toastr: ToastrService) {
  }

  ngOnInit() {

    
    $(".navbarheader").addClass("bggreen1");
    if (sessionStorage.getItem('subid') === 'null') {

      this.router.navigate(['/products']);
    } else {
      var jsondata = {

        "subscriptionId": sessionStorage.getItem('subid')

      };
      this.studentsubscriptionId = sessionStorage.getItem('subid');

      this.spinner.show();
      this.userservice.getallproductsbyid(jsondata).subscribe((data) => {
        this.productdetails = data;
        this.spinner.hide();
      })
    }

  }
  sendotp(user) {
    this.send = true;
    const jsondata = {
      primaryEmail: user.primaryEmail
    };
    this.spinner.show();
    this.userservice.sendotp(jsondata).subscribe((data: any) => {
      console.log(data)
      if (data.msg === "An OTP has been sent to your mail.Check the spam folder for OTP ") {
        this.isDisabled = false;
        this.otpid = data.otpid;
        this.send = false;
        this.resend = true;


        this.toastr.success("An OTP has been sent to your mail. (Please check spam folder also)");
      } else {

        this.toastr.warning(data.msg).onTap
        .subscribe(() => this.toasterClickedHandler());
        this.send = false;
      }
    },()=>{

    },()=>{
      this.spinner.hide();
    });
  }

  sendotpforForgetPassword(user) {

    this.send = true;
    const jsondata = {
      primaryEmail: user.primaryEmail
    };
    console.log(user)
    this.spinner.show();
    this.userservice.sendotpforForgetPassword(jsondata).subscribe((data: any) => {
      this.spinner.hide();
      if (data.msg == "An OTP has been sent to your mail. Check the spam folder for OTP ") {
        this.otpid = data.otpid;
        this.confirmcodeotp = true;

        this.userservice.GetUserDetailByEmailpost({ "primaryEmail": user.primaryEmail }).subscribe(data => {
          this.userinfo = data[0];
          console.log(data)
        })
        this.toastr.success("An OTP has been sent to your mail. (Please check spam folder also)");
      } else {

      }
    });
  }

  updatepassword(a) {
    const jsondata = {
      parentAccountId: this.userinfo.pAccountId,
      password: a
    };
    console.log(jsondata)
    this.spinner.show();
    this.userservice.updatepassword(jsondata).subscribe((data: any) => {
      this.toastr.success(data.msg);
      this.spinner.hide();
      // this.showforgetpassword = false;
      // this.showloginfrom = false;
    })
  }



  getstudents(data) {
    var json = {
      "primaryEmail": data
    }
    this.spinner.show();
    this.userservice.getstudentsfun(json).subscribe((data: any) => {
      //  console.log(data)
      this.spinner.hide();
      this.studentlist = data;
      this.showstudentslist = true;
    })

  }

  // verify otp

  verifyotp(user) {
    const jsondata = {
      primaryEmail: user.primaryEmail,
      otp: user.otp,
      otpId: this.otpid
    };
    console.log(jsondata);
    this.spinner.show();
    this.userservice.verifyotp(jsondata).subscribe((data: any) => {
      this.spinner.hide();
      if (data.msg === "OTP confirmed!") {
        this.enetrnewotp = true;
        this.isDisabled = true;
        this.isDisabled1 = false;
        this.show = true;

        this.toastr.success(data.msg);
      } else {
        this.isDisabled = true;

        this.isDisabled1 = true;
        this.toastr.warning(data.msg + " Please resend OTP to verify.");
      }
    });
  }
  toasterClickedHandler(){
    this.showregistration = false; this.showloginfrom = true;
  }

  removesuid() {
    sessionStorage.removeItem('subid');
  }
  parentdetails;
  login(data) {
    this.spinner.show();
    this.userservice.login(data).subscribe((data: any) => {
      console.log(data);
      this.spinner.hide();
      if (data.message == "Login successful!") {
        this.showlogin = false;
        this.showloginfrom = false;

        this.choosestudent = true;
        this.parentdetails = data;
        this.accountId = data.accountId;
        sessionStorage.setItem('paccountId', data.accountId);
        sessionStorage.setItem("primaryEmail", data.primaryEmail);
      } else {
        this.loginerror = true;
      }
    })
  }
  public ParentRegistration(data: any) {

    delete data.confirmPassword;
    data['userRole'] = "parent",
      data['gradeId'] = null,
      data['countryCode'] = data.phone.dialCode,
      data['subsTypeId'] = null,
      data['mobileNum'] = data.phone.number.replace(/ /g, "")
    delete data.phone;
    delete data.password1;

    this.spinner.show();




    this.userservice.registration(data).subscribe(
      (msg: any) => {
        // console.log(msg)
        this.spinner.hide();
        if (msg.msg === "Congratulations! Registration Successful.") {
          this.showstudentform = true;
          this.showlogin = false; this.showpayment = false; this.showregistration = false
          // this.userservice.userdetails = data;
          // this.toastr.success(msg.msg);
          this.accountId = msg.accountId;
          sessionStorage.setItem('paccountId', msg.accountId);
          // sessionStorage.setItem("accountId", msg.accountId);
          sessionStorage.setItem("primaryEmail", msg.primaryEmail);
        } else {
          this.toastr.warning(msg.msg);
        }
      },
      () => { },
      () => { }
    );
  }
  showexried() {
    // this.toastr.info('No existing students available(Note*: you can update student once the previous subscription is expired)');
  }

  makepayment1(data) {
    sessionStorage.setItem("sAccountId", data);
    this.makepayment();
  }

  makepayment2(data) {
    sessionStorage.setItem("sAccountId", data);
    const jsondata = {
      sAccountId: sessionStorage.getItem("sAccountId"),
      subsTypeId: sessionStorage.getItem("subid")
    };
    this.spinner.show();
    this.userservice.setsubit(jsondata).subscribe(data => {
      // console.log(data)
      this.spinner.hide();
      this.toastr.success("Congratulations! Registration Successful, proceed with login");
      window.scrollTo(0, 0);

    }, () => {

    }, () => {
      const jsondata1 = {
        studentAccountId: sessionStorage.getItem("sAccountId"),
        subsTypeId: sessionStorage.getItem("subid")
      };
      this.userservice.sendmail(jsondata1).subscribe(data => {
        console.log(data, 'mail delivered')
      })
    })

  }

  makepayment() {
    const tidData: any = new Date().getTime();
    var tid = Math.floor(10000 + Math.random() * 90000);
    // tid = tid.substring(0,15);
    // console.log(tid)
    const buynowData = {
      subsctypeId: this.productdetails.subscriptionId,
      parentAccountId: this.accountId,
      tid: tid,
      merchant_id: "187400",
      order_id: this.accountId + tid,
      amount: parseFloat(this.productdetails.price).toFixed(2)
    };
    // console.log(buynowData);
    this.spinner.show();
    this.userservice.paymentPost(buynowData).subscribe(
      (data: any) => {
        // console.log(data);
      }, () => {

      }, () => {
        this.spinner.hide();
        // window.location.href = "http://localhost:4900/#/paymentcheckout/"+this.accountId;
        const form = document.createElement("form");
        form.setAttribute("method", "post");

        form.setAttribute("action", "https://yolearn.com/ccavRequestHandler.php");

        const a = document.createElement("input"); // input element, text
        a.setAttribute("type", "text");
        a.setAttribute("name", "tid");
        a.setAttribute("value", tidData);

        const b = document.createElement("input"); // input element, text
        b.setAttribute("type", "text");
        b.setAttribute("name", "merchant_id");
        b.setAttribute("value", "187400");

        const c = document.createElement("input"); // input element, text
        c.setAttribute("type", "text");
        c.setAttribute("name", "order_id");
        c.setAttribute("value", this.accountId + tid);

        const d = document.createElement("input"); // input element, text
        d.setAttribute("type", "text");
        d.setAttribute("name", "amount");
        d.setAttribute("value", parseFloat(this.productdetails.price).toFixed(2));
        // d.setAttribute("value", '1');

        const e = document.createElement("input"); // input element, text
        e.setAttribute("type", "text");
        e.setAttribute("name", "currency");
        e.setAttribute("value", "INR");

        const f = document.createElement("input"); // input element, text
        f.setAttribute("type", "text");
        f.setAttribute("name", "redirect_url");
        f.setAttribute("value", "https://yolearn.com/ccavResponseHandler.php");
        // f.setAttribute("value", "https://yolearn.com/ccavResponseHandler.php");

        const g = document.createElement("input"); // input element, text
        g.setAttribute("type", "text");
        g.setAttribute("name", "cancel_url");
        g.setAttribute("value", "https://yolearn.com/ccavResponseHandler.php");
        // g.setAttribute("value", "https://yolearn.com/ccavResponseHandler.php");

        const h = document.createElement("input"); // input element, text
        h.setAttribute("type", "text");
        h.setAttribute("name", "language");
        h.setAttribute("value", "en");

        const submit = document.createElement("input");
        submit.setAttribute("type", "submit");
        submit.setAttribute("value", "submit");

        form.appendChild(a);
        form.appendChild(b);
        form.appendChild(c);
        form.appendChild(d);
        form.appendChild(e);
        form.appendChild(f);
        form.appendChild(g);
        form.appendChild(h);
        form.appendChild(submit);
        document.getElementsByClassName("body")[0].appendChild(form);

        form.submit();
        form.remove();
      });

    // this.router.navigate(['/paymentcheckout/'+this.accountId + tid])

  }

  change() {

    setTimeout(function () {

      $('#phone').attr('placeholder', 'Phone Number')
    }, 200)
  }
  scrolltotop() {
    window.scrollTo(0, 0);
  }

  studentregistration(data) {

    data.accountId = sessionStorage.getItem("paccountId");
    data.primaryEmail = sessionStorage.getItem("primaryEmail");
    data.userRole = 'student';
    // this.productdetails.price = 0;

    if (this.productdetails.price <= 0) {
      this.spinner.show();
      this.userservice.registerstudent(data).subscribe((data: any) => {
        console.log(data)
        this.spinner.hide();
        if (data.msg = "Congratulations! Registration Successful") {
          this.showpayment = true;
          this.showregistration = false;
          this.showstudentform = false;
          this.showlogin = false;
          this.toastr.success("Congratulations! Registration Successful, proceed with login");
          window.scrollTo(500, 0);
          sessionStorage.setItem('sAccountId', data.sAccountId);

        }
      }, () => {

      }, () => {
        const jsondata = {
          sAccountId: sessionStorage.getItem("sAccountId"),
          subsTypeId: sessionStorage.getItem("subid")
        };
        this.userservice.setsubit(jsondata).subscribe(data => {
          console.log(data)
        }, () => {

        }, () => {
          const jsondata1 = {
            studentAccountId: sessionStorage.getItem("sAccountId"),
            subsTypeId: sessionStorage.getItem("subid")
          };
          this.userservice.sendmail(jsondata1).subscribe(data => {
            console.log(data, 'mail delivered')
          })
        })

      })
    } else {
      this.spinner.show();
      this.userservice.registerstudent(data).subscribe((data: any) => {
        this.spinner.hide();
        if (data.msg = "Congratulations! Registration Successful") {
          // this.showpayment = true;
          // this.showregistration = false;
          // this.showstudentform = false;
          // this.showlogin = false;
          sessionStorage.setItem('sAccountId', data.sAccountId);
          var self=this;
          setTimeout(() => {
            self.makepayment();
          }, 200);
        }
      })
    }

  }

  onresetSubmit(psw, detail) {
    const jsondata = {
      parentAccountId: detail.pAccountId,
      password: psw.password
    };
    this.userservice.resetpassword(jsondata).subscribe(
      (data: any) => {
        if (data.msg === "updated password") {

        } else {

        }
      },
      () => {

      },
      () => {

      }
    );

  }
}
