import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ClassroomsComponent } from './classrooms/classrooms.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PricingComponent } from './pricing/pricing.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ProductsComponent } from './products/products.component';
import {UserService} from "./user.service";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import { CheckoutpageComponent } from './checkoutpage/checkoutpage.component';
import {FormsModule} from '@angular/forms';
import { PaymentchekComponent } from './paymentchek/paymentchek.component'
import { ToastrModule } from 'ngx-toastr';
import { SchoolproductsComponent } from './schoolproducts/schoolproducts.component';
import { HttpClientModule } from '@angular/common/http'; // <============
import { NgxSpinnerModule } from "ngx-spinner";
 

@NgModule({
  declarations: [
    AppComponent,
    ClassroomsComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PricingComponent,
    ProductsComponent,
    CheckoutpageComponent,
    PaymentchekComponent,
    SchoolproductsComponent,
  ],
  imports: [
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      enableHtml:true,
      // tapToDismiss:true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
   
   


  ],

  providers: [UserService ,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
