import {Component, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import { Observable } from 'rxjs';
import {forkJoin} from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public allproducts;
  public grdaes;

  constructor (private userservice: UserService) {
  }

  ngOnInit () {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
    this.userservice.getallgrades().subscribe(data => {
      this.grdaes = data;
      console.log(this.grdaes)
    },()=>{

    },()=>{
      const calls = [];
      this.grdaes.forEach(element => {
        var json = {
          "gradeId": element.gradeId
        }
        calls.push(
          this.userservice.getallproductsbygradeId(json)
          );
      });

      forkJoin(calls).subscribe(responses => {
        var merged = [].concat.apply([], responses);
        this.allproducts = merged;

       	console.log(this.allproducts)
      });
  
      console.log(calls)
    });
    $(".navbarheader").addClass("bggreen1");
   
    
    
    // this.userservice.getallproductsbygradeId(json).subscribe((data) => {
    //   if (data[0].msg == 'Empty list') {

    //   } else {
    //     this.allproducts = data;
    //     // console.log(data);

    //     // this.allproducts.push(data);
    //     // this.allproducts.push(data);
    //     // this.allproducts.push(data);
    //     // this.allproducts.push(data);
    //     // console.log(this.allproducts);
    //   }

    // },()=>{

    // },()=>{



    // });
  }

  setsubid (data) {
    sessionStorage.setItem('subid', data);
  }

  filterproduct (data) {
    var json = {
      "gradeId": data
    }
   
    
    this.userservice.getallproductsbygradeId(json).subscribe((data) => {
      if (data[0].msg) {
        this.allproducts = [];
      } else {
        this.allproducts = data;
        console.log(this.allproducts);
        var x = window.matchMedia("(max-width: 700px)");
        if (x.matches) { // If media query matches
          $('#sidebar').removeClass('active');
        } else {
          // document.body.style.backgroundColor = "pink";
        }
        
      }

    });
    
  }

  filterproduct1(){
    this.userservice.getallgrades().subscribe(data => {
      this.grdaes = data;
      console.log(this.grdaes)
    },()=>{

    },()=>{
      const calls = [];
      this.grdaes.forEach(element => {
        var json = {
          "gradeId": element.gradeId
        }
        calls.push(
          this.userservice.getallproductsbygradeId(json)
          );
      });

      forkJoin(calls).subscribe(responses => {
        var merged = [].concat.apply([], responses);
        this.allproducts = merged;
		
	

       
      });
  
      console.log(calls)
    });
  }

}
