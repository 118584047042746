import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor () {
  }

  ngOnInit () {
  }

  public popupWindow (a) {
    var newwindow=window.open(a,'name','toolbar=no,\n' +
      '                                    location=no,\n' +
      '                                    status=no,\n' +
      '                                    menubar=no,\n' +
      '                                    scrollbars=yes,\n' +
      '                                    resizable=yes,\n' +
      '                                    width=600,\n' +
      '                                    height=600\'');
    if (window.focus) {newwindow.focus()}
    return false;
  }

}
