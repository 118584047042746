import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, RouterModule, Routes} from '@angular/router';
import { ClassroomsComponent} from './classrooms/classrooms.component';
import { HomeComponent } from './home/home.component';
import {ProductsComponent} from "./products/products.component";
import {CheckoutpageComponent} from "./checkoutpage/checkoutpage.component";
import {PaymentchekComponent} from "./paymentchek/paymentchek.component";
import { SchoolproductsComponent } from './schoolproducts/schoolproducts.component';

export const routes : Routes = [
  {path: 'classroom/:id', component: ClassroomsComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'schoolproducts', component: SchoolproductsComponent},
  {path: 'checkout', component: CheckoutpageComponent},
  {path: 'paymentcheckout/:id', component: PaymentchekComponent },
  {path: '' , component: HomeComponent }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule],
  declarations: []
})
export class AppRoutingModule {
  constructor(r: ActivatedRoute) {
    r.url.subscribe((u) => {
      // console.log(u);
    });
  }
}
