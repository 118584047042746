import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public selectedgrade = '6';
  public class6program = [{program: ['Foundation']}, {subjects: ["Maths,Science"]}]
  public class7program = [{program: ['Foundation']}, {subjects: ["Maths,Science"]}]
  public class8program = [{program: ['Foundation']}, {subjects: ["Maths,Science"]}]
  public class9program = [{program: ['Foundation']}, {subjects: ["Maths,Science"]}]
  public class10program = [{program: ['Foundation']}, {subjects: ["Maths,Science"]}]

  constructor () {
  }

  ngOnInit () {


    if(this.detectIE()){
       document.getElementById('unsupported-message').style.display='block';
      $(".btnblink").css('background','none');
    }


    $(document).ready(function () {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 10) {
          $(".navbarheader").addClass("bggreen");
        }

        else {
          $(".navbarheader").removeClass("bggreen");
        }
      })
    })
  }


  public pricing () {
    $('html,body').animate({
        scrollTop: $(".pricingsection").offset().top
      },
      'slow');
  }


  public conatct () {
    $('html,body').animate({
        scrollTop: $(".conatct").offset().top
      },
      'slow');
  }

  public fadein1 () {
    $('.df').removeClass('activesubmenu ')
    $('.defaultselected' + this.selectedgrade).addClass('activesubmenu ')
    $(".foundationlist").slideUp('fast', function () {
      $(".foundationlist").slideDown();
    });
  }


  public hidedw () {
    $(".mega-menu").hide();
    $(".navbarheader").removeClass("bggreen2");
  }

  public displaymenu () {
    // $(".mega-menu").fadeIn();
    // $(".navbarheader").addClass("bggreen2");

  }

  public toggleicon (a) {
    $(a.target).next('ul').slideToggle('500');
    $(a.target).find('span').toggleClass('fa-angle-double-down fa-angle-double-up');
  }

  public removebg () {
    $(".navbarheader").removeClass("bggreen2");
  }

  public detectIE () {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return true;
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return true;
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false;
    }

    // other browser
    return false;
  }
}

