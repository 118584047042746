import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()


export class UserService {
  // private getallgrade = "https://yolearn.com:8443/mapsyolearndevJan20/grade/loadAllGrades";
  // private products = 'https://yolearn.com:8443/mapsyolearndevJan20/payment/getAllSubscriptionTypesByGradeId';
  // private productswithid = 'https://yolearn.com:8443/mapsyolearndevJan20/payment/listOfSubscriptionWithId';
  // private loginurl = 'https://yolearn.com:8443/mapsyolearndevJan20/users/loginAsParentOrStudent';
  // private payment = 'https://yolearn.com:8443/mapsyolearndevJan20/payment/paymentCheckout';
  // private billing = 'https://yolearn.com:8443/mapsyolearndevJan20/payment/getdetailsOfPayementByParentUsingOrder';
  // private studentregister = 'https://yolearn.com:8443/mapsyolearndevJan20/users/user_signup';
  // private updatetoparenturl = 'https://yolearn.com:8443/mapsyolearndevJan20/users/updateGuestToParent';
  // private setsubidurl = 'https://yolearn.com:8443/mapsyolearndevJan20/users/assignSubsTypeIdToStudent';
  // private allotsubidurl = 'https://yolearn.com:8443/mapsyolearndevJan20/payment/allotSubscriptionTo';
  // private guestregisterurl = 'https://yolearn.com:8443/mapsyolearndevJan20/users/guestRegister';
  // private hostur = 'https://yolearn.com:8443/mapsyolearndevJan20/';
  // maapsyolearnmay20200

   private getallgrade = "https://yolearn.com:8443/mapsdemo/grade/loadAllGrades";
  private products = 'https://yolearn.com:8443/mapsdemo/payment/getAllSubscriptionTypesByGradeId';
  private productswithid = 'https://yolearn.com:8443/mapsdemo/payment/listOfSubscriptionWithId';
  private loginurl = 'https://yolearn.com:8443/mapsdemo/users/loginAsParentOrStudent';
  private payment = 'https://yolearn.com:8443/mapsdemo/payment/paymentCheckout';
  private billing = 'https://yolearn.com:8443/mapsdemo/payment/getdetailsOfPayementByParentUsingOrder';
  private studentregister = 'https://yolearn.com:8443/mapsdemo/users/user_signup';
  private updatetoparenturl = 'https://yolearn.com:8443/mapsdemo/users/updateGuestToParent';
  private setsubidurl = 'https://yolearn.com:8443/mapsdemo/users/assignSubsTypeIdToStudent';
  private allotsubidurl = 'https://yolearn.com:8443/mapsdemo/payment/allotSubscriptionTo';
  private guestregisterurl = 'https://yolearn.com:8443/mapsdemo/users/guestRegister';
  private hostur = 'https://yolearn.com:8443/mapsdemo/';
  private getstudents = 'https://yolearn.com:8443/mapsdemo/users/getAllStudentsOfParent';
  private sendotpforforgetpassword = 'https://yolearn.com:8443/mapsdemo/mail/sendotpwithoutValidation';
  private ForgotPassword = 'https://yolearn.com:8443/mapsdemo/users/parentForgotPassword';
  private GetUserDetailByEmail = 'https://yolearn.com:8443/mapsdemo/users/getAllStudentsOfParent';
  private sendmailurl = 'https://yolearn.com:8443/mapsdemo/users/send-mail-to-no-payment';
  private getschoolproducts = 'https://yolearn.com:8443/mapsdemo/payment/getProductsByStaus';
  

  
  



  
  // private getallgrade = "http://yolearn.com:8080/mapsdemo/grade/loadAllGrades";
  // private products = 'http://yolearn.com:8080/mapsdemo/payment/getAllSubscriptionTypesByGradeId';
  // private productswithid = 'http://yolearn.com:8080/mapsdemo/payment/listOfSubscriptionWithId';
  // private loginurl = 'http://yolearn.com:8080/mapsdemo/users/loginAsParentOrStudent';
  // private payment = 'http://yolearn.com:8080/mapsdemo/payment/paymentCheckout';
  // private billing = 'http://yolearn.com:8080/mapsdemo/payment/getdetailsOfPayementByParentUsingOrder';
  // private studentregister = 'http://yolearn.com:8080/mapsdemo/users/user_signup';
  // private updatetoparenturl = 'http://yolearn.com:8080/mapsdemo/users/updateGuestToParent';
  // private setsubidurl = 'http://yolearn.com:8080/mapsdemo/users/assignSubsTypeIdToStudent';
  // private allotsubidurl = 'http://yolearn.com:8080/mapsdemo/payment/allotSubscriptionTo';
  // private guestregisterurl = 'http://yolearn.com:8080/mapsdemo/users/guestRegister';
  // private hostur = 'http://yolearn.com:8080/mapsdemo/';






  constructor(private http: HttpClient) {

  }

  getstudentsfun(data){
    return this.http.post(this.getstudents, data);
  }
  sendmail(data){
    return this.http.post(this.sendmailurl, data);

  }
  getallproductsbygradeId1(data){
    console.log(data)
    return this.http.post(this.getschoolproducts, data);

  }
  
  getallproductsbygradeId(data) {
    return this.http.post(this.products, data);
  }

  getallproductsbyid(data) {
    return this.http.post(this.productswithid, data);
  }

  GetUserDetailByEmailpost(data){

    return this.http.post(this.GetUserDetailByEmail, data);
  }

  updatepassword(data){
    return this.http.post(this.ForgotPassword, data);

  }

  login(data) {
    return this.http.post(this.loginurl, data);
  }

  paymentPost(data) {
    return this.http.post(this.payment, data);
  }

  billingdetails(data) {
    return this.http.post(this.billing, data);
  }

  registerstudent(data) {
    return this.http.post(this.studentregister, data);

  }

  sendotpforForgetPassword(data) {
    return this.http.post(this.sendotpforforgetpassword, data);
  }

  updatetoparent(data) {
    return this.http.post(this.updatetoparenturl, data);
  }
  resetpassword(data){
    // resetpassword(data) {
      return this.http.post(this.ForgotPassword, data);
    // }
  }
  setsubit(data) {
    return this.http.post(this.setsubidurl, data);

  }

  allotPackageToStudent(data) {
    return this.http.post(this.allotsubidurl, data);
  }

  getallgrades() {
    return this.http.get(this.getallgrade);
  }
  guestregister(val) {
    return this.http.post(this.guestregisterurl, val);
  }

  sendotp(data) {
    return this.http.post(this.hostur + 'mail/sendotp', data);
  }

  // send otp in case of forget password

  registration(users) {
    // console.log(users)
    // console.logthis.hostur + 'mail/sendotp', data)
     return this.http.post(this.hostur + 'users/user_signup', users);
  }

  // verify otp

  verifyotp(data) {
    return this.http.post(this.hostur + 'mail/confirmOtp', data);
  }
}
