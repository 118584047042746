import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
// import { WOW } from 'wowjs/dist/wow.min';
import anime from 'animejs';
import {UserService} from "../user.service";

declare var WOW: any;



var self1;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public self1;
  public class6intro = "Class VI is a vital phase in the student’s academic year. It is here that the subjects become more diverse and more complex representing a strong challenge to fledgling mind. With this mind, we have developed a learning model for Class 6. This builds and strengthens the fundamental concepts which will be valuable both in academics and competitive exams.";
  public class7intro = 'On being acquainted with complexity of core subjects in class 6, now on students need to explore those concepts and apply them in real life contexts, brushing up their psychomotor domains (scientific inquiry skills) with a critical reflection in maths and sciences, which helps them to gain insight into their strengths. <br/><br/><br/> At this juncture, our conceptualizing teaching methods in mathematics and sciences allows Children to explore concepts as well as develop their own ways of solving problems.';
  public class8intro = "This is the stage of transition towards greater abstraction, where the child will move from using concrete materials and experiences to deal with abstract notions. Class 8 is the bridge to class 9, where children deal with more formal mathematics. <br/><br/> At yolearn we use facilitative teaching methods by explaining leading questions that help generate understanding, ensuring that all students give their best effort and full attention, by clearing any misconceptions they have about the lessons taught. We work on large number of problems from the NCERT books and beyond in the live classes to inculcate the skill of applying the learned concepts.";
  public class9intro = "Our learning program offers a detailed, captivating and individualized learning experience. Our teachers impart their knowledge by breaking down complex concepts into easy micro concepts thus making students to relate and comprehend them better.<br/><br/>The adaptive learning features of our portal supports the students to learn at their own pace and style. We focus on guiding students to understand the basics of every concept with good shortcut techniques thus enabling them with lifetime retention.";
  public class10intro = "Class 10 is very crucial in every student`s life, be it because of the first exam in which the student`s performance will be measured at national level or score in the final board exam plays an important role in selecting the stream of student`s choice in class 11, or grade 10 score will be taken as one of the bench marks in assessing the academic profile of a student during the selection for employment in later stages.<br><br>Whatever may be the reason, most of the students undergo some stress and anxiety during this phase unless they are properly prepared with strong base in the concepts and smart enough in applying these concepts followed by good practice.<br><br>At yolearn, our proven innovative teaching methodologies and experienced faculty will definitely help the student to come out with flying colours in this crucial phase by securing higher percentages.";
  public selected = "6";
  public styleindex = 0;

  public stylearray = ["Personalized learning experience", "Emphasis laid on strengthening the fundamentals", "Innovative teaching methodology", "Learn anytime anywhere"];
  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  Number1 = "\\+?\\d[\\d -]{3,12}\\d";

  constructor (private userservice: UserService,) {
  }

  ngOnInit () {
    if (this.detectIE()) {
      document.getElementById('unsupported-message').style.display = 'block';
      $(".btnblink").css('background', 'none');
    }
    self1 = this;
    this.eachletter();
    $(".classroomdiv>li").click(function (e) {
      $(".classroomdiv>li").removeClass("active");
      $(".tab-content>div").addClass("fade")
      $(".tab-content>div").removeClass("active")

      $(e.target).addClass("active");
      var num = $(e.target).attr("text");

      $(e.target).addClass("active");
      $(".div" + num).removeClass("fade");
      $(".div" + num).addClass("active");
    })
    $(".navbarheader").removeClass("bggreen1");
    var wow = new WOW(
      {
        animateClass: 'animated',
        offset: 100,
        callback: function (box) {
        }
      }
    );
    wow.init();


//Initialise Bootstrap Carousel Touch Slider
// Curently there are no option available.

    const self = this;


    $('.intro').addClass('go');

    $('.reload').click(function () {
      $('.intro').removeClass('go').delay(200).queue(function (next) {
        $('.intro').addClass('go');
        next();
      });

    });
    $("#menutoggle").click(function () {
      $('.xs-menu').toggleClass('displaynone');

    });

    //add active class on menu
    $('ul li').click(function (e) {
      e.preventDefault();
      $('li').removeClass('active');
      $(this).addClass('active');
    });
    //drop down menu
    $(".drop-down").hover(function () {
      $('.mega-menu').addClass('display-on');
    });
    $(".drop-down").mouseleave(function () {
      $('.mega-menu').removeClass('display-on');
    });
  }

  videoplay (data, data1) {

    if ($('.iphone8').css('display') === 'block') {
      (<HTMLVideoElement>document.getElementById('datavideo1')).src = data;
      (<HTMLVideoElement>document.getElementById('datavideo1')).play();
    } else {
      (<HTMLVideoElement>document.getElementById('datavideo')).src = data;

      (<HTMLVideoElement>document.getElementById('datavideo')).play();
    }


    $('.vidoetext').css('text-decoration', 'none');
    $(data1.target).css('text-decoration', 'underline');

  }

  aboutus () {
    $('html,body').animate({
        scrollTop: $(".aboutus").offset().top
      },
      'slow');
  }

  eachletter () {
    $('.l1').each(function () {
      $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
    });
    this.do_animate();
  }

  do_animate () {

    anime.timeline({loop: false})
      .add({
        targets: '.l1 .letter',
        translateY: [100, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1400,
        delay: function (el, i) {
          return 300 + 30 * i;
        }
      }).add({
      targets: '.l1 .letter',
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: function (el, i) {
        return 300 + 30 * i;
      },
      complete: function (anim) {
        $(".l1").text(self1.stylearray[self1.styleindex]);

        self1.styleindex++;
        if (self1.styleindex >= self1.stylearray.length) {
          self1.styleindex = 0;
        }
        self1.eachletter();

      }
    });

  }

  public detectIE () {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return true;
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return true;
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false;
    }

    // other browser
    return false;
  }


  register (val) {
    var json ={
      "email":val.primaryEmail,
      "phoneNumber":val.Number1
    }
    this.userservice.guestregister(json).subscribe(data => {

    }, () => {

    }, () => {
      window.open("https://app.yolearn.com/#/Guest/" + val.radiomodel + "/recordedGrid", "_self");

    });
  }
}
