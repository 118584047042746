import {Component, OnInit, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-classrooms',
  templateUrl: './classrooms.component.html',
  styleUrls: ['./classrooms.component.css']
})
export class ClassroomsComponent implements OnInit, AfterViewInit {
  public classroom6 = "class 6";
  public classroom7 = "class 7";
  public classroom8 = "class 8";
  public classroom9 = "class 9";
  public classroom10 = "class 10";
  public ScoreWell = "Score Well";


  public class6intro = "Class VI is a vital phase in the student’s academic journey. It is here that the subjects become more diverse and more complex representing a strong challenge to the fledgling minds. Considering   the facts, we have developed a learning model for class 6. This builds and strengthens the fundamental concepts which will be valuable for the future competitive exams.";
  public class7intro = 'On being acquainted with complexity of core subjects in class 6, now on students need to explore those concepts and apply them in real life contexts, brushing up their psychomotor domains (scientific inquiry skills) with a critical reflection in maths and sciences, which helps them to gain insight into their strengths. <br/><br/><br/> At this juncture, our conceptualizing teaching methods in mathematics and science allows Children to explore concepts as well as develop their own ways of solving problems.';
  public class8intro = "This is the stage of transition towards greater abstraction, where the child will move from using concrete materials and experiences to deal with abstract notions. Class 8 is the bridge to class 9, where children deal with more formal mathematics. <br/><br/> At YOLEARN we use facilitative teaching methods by explaining leading questions that help generate understanding, ensuring that all students give their best effort and full attention, by clearing any misconceptions they have about the lessons taught. We work on large number of problems from the NCERT books and beyond in the live classes to inculcate the skill of applying the learned concepts.";
  public class9intro = "Our learning program offers a detailed, captivating and individualized learning experience. Our teachers impart their knowledge by breaking down complex concepts into easy micro concepts thus making students to relate and comprehend them better.<br/><br/>The adaptive learning features of our portal supports the students to learn at their own pace and style. We focus on guiding students to understand the basics of every concept with good shortcut techniques thus enabling them with lifetime retention.";
  public class10intro = "Class 10 is very crucial in every student`s life, be it because of the first exam in which the student`s performance will be measured at national level or score in the final board exam plays an important role in selecting the stream of student`s choice in class 11, or grade 10 score will be taken as one of the bench marks in assessing the academic profile of a student during the selection for employment in later stages.<br><br>Whatever may be the reason, most of the students undergo some stress and anxiety during this phase unless they are properly prepared with strong base in the concepts and smart enough in applying these concepts followed by good practice.<br><br>At YOLEARN, our proven innovative teaching methodologies and experienced faculty will definitely help the student to come out with flying colours in this crucial phase by securing higher percentages.";
  public ScoreWellintro = "Gateway to score good marks <br> <br>\
  This revision program is for class 6th to 10th CBSE students.<br> <br>\
  Students often cannot give their best in the exam because of improper revision\
   of the content they learned over the whole academic year. Systematic\
    revision helps the students to recollect and do well in the examinations.\
  Yolearn designed this score well program to revise the whole \
   syllabus in a very effective and systematic approach in the examination \
   point of view. This helps the students to clear their doubts and reinforce \
   the concepts they have learned. They will be taught on dealing the twisted questions.\
  We are offering this revision program for class 6th to class 10th science and Maths subjects of CBSE syllabus.";


  public foundationtext = "This program offers comprehensive coverage of entire syllabus of mathematics and science subjects through live interactive classes.This course mainly focus on the familiarization of concepts, thereby lays a strong foundation for their future <span class='yostrong'>competitive examinations</span>. Proven teaching methodologies by the highly experienced teachers ensures the development of critical thinking and problem solving skills of the students"
  public foundationtext1 = "In this revision program we deal with the important and tough concepts, MCQs, short answer questions etc chapter wise, in view of the final examinations."
  public ScoreWelltest = "This program helps the students to understand the tough topics in the easiest way, which will boost their confidence levels and reduce the fear, ultimately to score well in the examinations"
  public Olympiad = "Olympiads do not lead directly to any career benefits. Rather, they provide a stimulus to begin a career in science or mathematics, to undertake a lifelong journey into the realms of exciting intellectual challenges.  This helps students to check the position in the competitive world. It increases the competitive awareness and help the students in improving the conceptual understanding, problem solving skills, reasoning and analytical skills."

  public olympiadfeature = [
    "This is 45 days programe (approx.)",
    "All the Science and Maths concepts will be covered. (IMO & NSO syllabus is similar to that of CBSE & state boards, except reasoning )",
    "Reasoning will also be covered in this program.",
    "Problem Solving ( IMO & NSO Pattern)",
    "Assignments for practice ( at the end of every Chapter)",
    "Doubts Clarification Sessions",
    "Weekly tests",
    "Grand Tests",
    "unlimited access to the recorded classes.    "]
  public class6feature = [
    "In depth familiarization of concepts by experienced subject experts through <span class='yostrong'>live interactive classes.</span>",
    "Application of concepts by practicing problems from NCERT books and advanced complex models in view of the future <span class='yostrong'>competitive examinations</span> like JEE ,SAT 2 etc. in live classes.",
    "Dedicated sessions for <span class='yostrong'>doubts clarification</span> by subject experts(live classes).",
    "Every topic taught in the class will be followed by <span class='yostrong'>assignments</span> for more practice.",
    "End of the chapter tests and <span class='yostrong'>periodical tests.</span>",
    "<span class='yostrong'>Unlimited </span>access to the Recorded live classes.",
    "Quick <span class='yostrong'>Revision </span>notes.",
    "A short term crash course also embeded in this curriculum for IMO & NSO(Olympiad)",
    "Quick revision classes andmodel question paper discussions before examination"]

  public class7feature = ["In depth familiarization of concepts by experienced subject experts through <span class='yostrong'>live interactive classes.</span>",
    "Application of  concepts by practicing problems from NCERT books and advanced  complex models in view of the future <span class='yostrong'>competitive examinations</span> like JEE ,SAT 2 etc.  in live classes.",
    "Dedicated sessions for <span class='yostrong'>doubts clarification</span> by subject experts(live classes).",

    "Every topic taught in the class will be followed by  <span class='yostrong'>assignments</span> for more practice.",

    "End of the chapter tests and <span class='yostrong'>periodical tests.</span>",

    "<span class='yostrong'>Unlimited </span>access to the Recorded live classes.",

    "<span class='yostrong'>Revision </span>notes to save time.",
    "A short term crash course also embeded in this curriculum for IMO & NSO(Olympiad)",
    "Quick revision classes andmodel question paper discussions before examination"]

  public class8feature = [
    "In depth familiarization of concepts by experienced subject experts through <span class='yostrong'>live interactive classes.</span>",
    "Appliction of  concepts by practicing problems from NCERT books and advanced  complex models in view of the future <span class='yostrong'>competitive examinations</span> like JEE ,SAT 2 etc.  in live classes.",
    "Dedicated sessions for <span class='yostrong'>doubts clarification</span> by subject experts(live classes).",
    "End of the chapter tests and <span class='yostrong'>periodical tests</span>.",
    "<span class='yostrong'>Unlimited </span>access to the Recorded live classes.",
    "Quick <span class='yostrong'>Revision </span>notes.",
    "A short term crash course also embeded in this curriculum for IMO & NSO(Olympiad)",
    "Quick revision classes andmodel question paper discussions before examination"]

  public class9feature = [
    "In depth familiarization of concepts by experienced subject experts through <span class='yostrong'>live interactive classes.</span>",
    "Application of  concepts by practicing problems from NCERT books and advanced  complex models in view of the future <span class='yostrong'>competitive examinations</span> like JEE ,SAT 2 etc.  in live classes.",
    "Dedicated sessions for <span class='yostrong'>doubts clarification</span> by subject experts(live classes).",
    "Every topic taught in the class will be followed by  <span class='yostrong'>assignments</span> for more practice.",
    "End of the chapter tests and <span class='yostrong'>periodical tests</span>.",
    "<span class='yostrong'>Unlimited </span>access to the Recorded live classes.",
    "Quick <span class='yostrong'>Revision </span>notes to save time.",
    "A short term crash course also embeded in this curriculum for IMO & NSO(Olympiad)",
    "Quick revision classes andmodel question paper discussions before examination"]
  

  public class10feature = [
    "In depth familiarization of concepts by experienced subject experts through <span class='yostrong'>live interactive classes.</span>",
    "Application of concepts by practicing problems from NCERT books and advanced complex models in view of the future <span class='yostrong'>competitive examinations</span> like JEE, SAT 2 etc.  in live classes.",
    "Dedicated sessions for <span class='yostrong'>doubts clarification</span> by subject experts(live classes).",
    "Every topic taught in the class will be followed by <span class='yostrong'>assignments</span> for more practice.",
    "End of the chapter tests and <span class='yostrong'>periodical tests.</span> ",
    "<span class='yostrong'>Unlimited </span>access to the Recorded live classes.",
    "Quick <span class='yostrong'>Revision </span>notes to save time.",
    "Discussions on previous years board questions in the live classes.",
    "Mock tests.",
    "A short term crash course also embeded in this curriculum for IMO & NSO(Olympiad)",
    "Quick revision classes andmodel question paper discussions before examination"]
  

  public scorewellfeature = [
    "Students will be taught by the best members of faculty in India.",
    "Online teaching through live interactive classes.",
    "Free and Unlimited access to the recorded classes.",
    "Students can interact with the teacher to clarify their doubts spontaneously right from the comfort of their homes.",
    
  ]


  public class6maths = [
    {
      name: ["1. Knowing our Number"],
      chapters: [
        "Comparing Numbers ",
        "Ascending,Descending Order",
        "Shifting Digits",
        "Indian & International System",
        "Place Value & Face Value",
        "Roman Numerals."
      ]
    },
    {
      name: ["2. Whole Numbers"],
      chapters: [
        "Natural No’s ",
        "Properties",
        "Successor & Predecessor",
        "Number line",
        "Properties",
      ]
    },
    {
      name: ["3. Playing with Numbers"],
      chapters: [
        "Factors & Multiples ",
        "Properties",
        "Perfect No’s",
        "Even No’s",
        "Odd No’s",
        "Common Factors & multiples",
        "Prime & Composite No’s",
        "Factorization",
        "Test of Divisibility",
        "H.C.F, L.C.M"]
    },
    {
      name: ["4. Geometrical Ideas"], chapters: [
      "Some Basic Terms",
      "Point",
      "Line",
      "Plane",
      "Incidence Properties in a plane",
      "Intersecting & Parallel lines",
      "Collinear Points",
      "Concurrent Points",
      "Line Segment",
      "Comparison of line segments",
      "Ray Curves",
      "Polygons"]
    },
    {
      name: ["5. Understanding Elementary Shapes"], chapters: [

      "Angle & its magnitude",
      "Comparison of Angles",
      "Interior & Exterior of an Angle",
      "Some Special Angles",
      "Degree Measure of an Angle",
      "Intersecting & Parallel lines",
      "Kinds of Angles"]
    },
    {
      name: ["6. Integers"], chapters: [
      "Integers",
      "Point",
      "+ ve, -ve",
      "Ordering of Integers ",
      "Absolute Value",
      "Addition",
      "Properties of Addition",
      "Subtraction",
      "Properties of subtraction"]
    },
    {
      name: ["7. Fractions"], chapters: [
      "Number line",
      "Fraction as division",
      "Type of Fraction",
      "Conversion ",
      "Equivalent Fractions",
      "Like & Unlike Fractions",
      "Comparing & Ordering",
      "Addition & Subtraction of Like & Unlike Fractions"]
    }, {
      name: ["8. Decimals"], chapters: [
        "An extension of place value table",
        "As Fractions",
        "Tenths",
        "Hundredths ",
        "Thousandths",
        "Expressing Fractions as decimals",
        "Comparing",
        "Uses of decimal Notation",
        "Equivalent Decimals",
        "Like & Unlike Decimals",
        "Conversion",
        "Addition & Subtraction of Decimals"]
    },
    {
      name: ["9. Data Handling"], chapters: [
      "Presentation of Data",
      "Construction of frequency Distribution",
      "Pictographs",
      "Interpretation Bar graph "]
    }, {
      name: ["10. Mensuration"], chapters: [
        "Length (units)",
        "Closed Curves",
        "Regions",
        "Perimeter ( le, le, Square Area (Rectangle, Square) "]
    }, {
      name: ["11. Algebra"], chapters: [
        "Use of letters to denote No’s",
        "Operations on literals & No’s (+, -) (x )",
        "Properties",
        "Powers of literal No’s",
        "Equation",
        "Variables & Constants"]
    }, {
      name: ["12. Ratio, Proportion"], chapters: [
        "Ratio",
        "Ratio in Simplest Form",
        "Equivalent Ratio",
        "Proportion",
        "Unitary method",
        "Continued",
        "Mean"]
    }, {
      name: ["13. Symmetry"], chapters: [
        "Line of symmetry",
        "Construction of symmetrical figures",
        "Symmetrical figures with two lines of symmetry",
        "Symmetry for some figures."]
    },
    {
      name: ["14. Practical Geometry"], chapters: [
      "Ruler & Compare Constructions",
      "Circle",
      "Constriction",
      "Line Segment",
      "Perpendiculars",
      "Angles"]
    }
  ];
  public class6science = [
    {
      name: ["1. Food"],
      chapters: [
        "Sources of Food ",
        "Components of Food",
        "Cleaning food"
      ]
    },
    {
      name: ["2. Materials"],
      chapters: [
        "Materials of daily use",
        "Different kinds of materials",
        "How things change/react with one another",
        "Number line"
      ]
    },
    {
      name: ["3. The World of the Living"],
      chapters: [
        "Things around us ",
        "The habitat of living",
        "Plants – Form and Function",
        "Animals – form and function"
      ]
    },
    {
      name: ["4. Moving Things, People and Ideas"], chapters: [
      "Moving",
      "Distance Measurement"
    ]
    },
    {
      name: ["5. How things work"], chapters: [

      "Electric current and circuits",
      "Magnets"
    ]
    },
    {
      name: ["6. Natural Phenomena"], chapters: [
      "Rain",
      "thunder and lightning",
      "Light"
    ]
    },
    {
      name: ["7. Natural Resources"], chapters: [
      "Importance of Water",
      "Importance of air"
    ]
    }
  ];

  public class7maths = [
    {
      name: ["1. Integers "],
      chapters: [
        "Various types of Numbers",
        "Operations",
        "Properties"
      ]
    },
    {
      name: ["2. Fractions & Decimals"],
      chapters: [
        "Various types of fractions",
        "Conversion of Unlike to like fractions",
        "Irreducible fractions",
        "Comparing fractions",
        "Operations",
        "Reciprocals ",
        "Like & Unlike decimals",
        "Converting fractions to decimals ",
        "Operations ",
        "Division by while No’s "
      ]
    },
    {
      name: ["3. Data Handling"],
      chapters: [
        "Array",
        "Mean",
        "Median",
        "Mode",
        "Empirical Formulae "
      ]
    },
    {
      name: ["4. Simple Equations"], chapters: [
      "Setting up an equation",
      "Solving an equation",
      "Transposing",
      "From Solution to equation",
      "Applications"
    ]
    },
    {
      name: ["5. Lines & Angles"], chapters: [
      "Complementary Angles",
      "Supplementary Angles",
      "Adjacent Angles",
      "Linear pair of Angles",
      "Vertically opposite angles"
    ]
    },
    {
      name: ["6. The Triangle and its Properties"], chapters: [
      "Terms: Side, Angles, Vertices",
      "Medians",
      "Altitude",
      "Properties",
      "Special triangles",
      "Sum of length of the sides",
      "Right angled triangle",
      "PYTHAGORAS Property"
    ]
    },
    {
      name: ["7. Congruence of Triangles"], chapters: [
      "Congruent Figures",
      "Some types of Congruent",
      "Congruence of Trianglesn",
      "Congruence & Area "
    ]
    }, {
      name: ["8. Comparing Quantities"], chapters: [
        "Ratio",
        "Property",
        "Comparison ",
        "Equivalent Ratios ",
        "Percentages",
        "Converting Fractional Numbers to Percentages.",
        "Converting Decimals to percentage",
        "Converting percentages to fractions to Decimals",
        "Ratios to Percents",
        "Increase / Decrease as percent",
        "Profit / loss as a percentage",
        "Interest for multiple years."]
    },
    {
      name: ["9. Rational Numbers"], chapters: [
      "Need of Rational No’s",
      "Numerator / Denominator",
      "Equivalent Rational No’s ",
      "+Ve,  - Ve Rational No’s",
      "Rational No’s on Number line",
      "Rational No’s in a Standard form",
      "Comparison of Rational No’s",
      "Rational No’s B/W Rational No’s"
    ]
    },
    {
      name: ["10. Practical Geometry"], chapters: [
      "Parallel lines.",
      "Distance b/w Parallel lines",
      "Transversal ",
      "Angles formed when the Transversal cuts 2 parallel lines",
      "Various types of Triangles.",
      "Pythagoras Theorem",
      "Congruence ",
      "ASA, SAS, SSS Congruence"
    ]
    },
    {
      name: ["11. Perimeter & Area"], chapters: [
      "Squares & Rectangles",
      "Triangles as a parts of Rectangles",
      "Parallelogram",
      "Circles",
      "Circumference of a Circle",
      "Applications"
    ]
    },
    {
      name: ["12. Algebraic Expressions"], chapters: [
      "Constants",
      "Variables   → Factors, Coefficients ",
      "Various types of expressions",
      "Addition & Subtraction of Algebraic Expressions ",
      "Rules for Number Patterns"
    ]
    },
    {
      name: ["13. Exponents & Powers"], chapters: [
      "Exponents",
      "Laws of Exponents",
      "Multiplying powers with the same base.",
      "Dividing powers with the same base ",
      "Taking power of a power.",
      "Multiplying powers with the same exponents",
      "Dividing powers with same exponents.",
      "Decimal Number System",
      "Expressing large No’s in Standard Form"
    ]
    },
    {
      name: ["14. Symmetry"], chapters: [
      "Reflection Symmetry",
      "Lines of Symmetry for Regular Polygons",
      "Rotational Symmetry"
    ]
    },
    {
      name: ["15. Visualizing Solid Shapes"], chapters: [
      "Plane Figures",
      "Solid Shapes",
      "(2-D) (3-D) Shapes",
      "Vertices, Edges, Shapes",
      "Oblique sketch",
      "Isometric Sketch",
      "Visualizing Solid Shapes.",
      "Different Sections in a Solid"
    ]
    }
  ];
  public class7science = [
    {
      name: ["1. Nutrition in Plants"],
      chapters: [
        "Mode of nutrition plants",
        "Photosynthesis – Food making process in plants",
        "Other modes of nutrition in plants",
        "Saprotrophs"
      ]
    },
    {
      name: ["2. Nutrition in animals"],
      chapters: [
        "Different ways of taking food",
        "Digestion in Humans",
        "Digestion in grass eating animals",
        "Feeding and digestion in Amoeba"
      ]
    },
    {
      name: ["3. Fibre to Fabric"],
      chapters: [
        "Wool",
        "Silk"
      ]
    },
    {
      name: ["4. Heat"], chapters: [
      "Hot and cold",
      "Measuring  temperatures",
      "Laboratory Thermometer",
      "Transfer of heat",
      "Kinds of clothes we wear in summer and winter"
    ]
    },
    {
      name: ["5. Acids, Bases and Salts"], chapters: [
      "Acids and Bases",
      "Natural indicators around us",
      "Neutralization",
      "Neutralization in every day life"
    ]
    },
    {
      name: ["6. Physical and chemical changes"], chapters: [
      "Physical Changes",
      "Chemical Change",
      "Rusting of Iron"
    ]
    },
    {
      name: ["7. Weather, Climate and Adaptations of animals to climate"], chapters: [
      "Weather",
      "Climate",
      "Climate and adaptation"
    ]
    }, {
      name: ["8. Winds, Storms and Cyclones"], chapters: [
        "Air exerts pressure",
        "High speed winds are accompanied by reduced air pressure",
        "Air expands on heating ",
        "Wind currents are generated due to uneven heating on the earth",
        "Thunder storms and cyclones",
        "Destructions caused by cyclones",
        "Effective safety measures",
        "Advanced technology has helped"
      ]
    },
    {
      name: ["9. Soil"], chapters: [
      "Soil teeming with life",
      "Soil profile",
      "Soil types",
      "Properties of soil",
      "Moisture in soil",
      "Absorption of water by soil",
      "Soil and crops"
    ]
    },
    {
      name: ["10. Respiration in organisms"], chapters: [
      "Why do we respire",
      "Breathing",
      "How do we breathe? ",
      "Why do we breathe out?",
      "Breathing in other animals",
      "Breathing under water",
      "Do plants also respire? "
    ]
    },
    {
      name: ["11. Transportation in animals and plants"], chapters: [
      "Circulatory System",
      "Excretion in animals",
      "Transport of substance in plants"
    ]
    },
    {
      name: ["12. Reproduction in plants"], chapters: [
      "Modes of reproduction",
      "Sexual reproduction",
      "Fruits and seeds formation",
      "Seed Dispersal "
    ]
    },
    {
      name: ["13. Motion and time"], chapters: [
      "Slow or fast",
      "Speed",
      "Measurement of time",
      "Measuring speed"
    ]
    },
    {
      name: ["14. Electric Current and its effects"], chapters: [
      "Symbols of electric components",
      "Heating effects of electric current",
      "Magnetic Effect of electric current",
      "Electromagnet",
      "Electric Bell"
    ]
    },
    {
      name: ["15. Light"], chapters: [
      "Light travels along a straight line",
      "Reflection of light",
      "Right or left",
      "Playing with spherical mirrors",
      "Images formed by lenses",
      "Sunlight – White or Colored?"
    ]
    },
    {
      name: ["16. Water: A Precious resource"], chapters: [
      "How much water is available",
      "Forms of water",
      "Ground water as an important source of water",
      "Depletion of water table",
      "Distribution of water",
      "Water Management",
      "What roles you can play",
      "Effects of water scarcity on plants"
    ]
    },
    {
      name: ["17. Forest: Our Lifeline"], chapters: [
      "Visit to a Forest"]
    },
    {
      name: ["18. Wastewater Story"], chapters: [
      "Water, Our Lifeline",
      "What is Sewage",
      "Water freshness up – An eventful Journey",
      "Wastewater Treatment Plant (WWTP)",
      "Better Housekeeping Practices",
      "Sanitation and Disease",
      "Alternative Arrangement for sewage Disposal",
      "Alternative Arrangement for sewage Disposal",
      "Sanitation at public places"
    ]
    }
  ];

  public class8maths = [
    {
      name: ["1. Rational Numbers"], chapters: [
      " Natural No’s",
      " Whole No’s",
      " Integers",
      " Properties",
      " Negative of a Number",
      " Reciprocal",
      " Representation of Rational No’s on Number line.",
      " Rational No’s B/w Rational No’s.",
    ]
    },
    {
      name: ["2. Linear Equations in One Variable"], chapters: [
      " Solving equation with linear expressions ",
      " Applications",
      " Solving equation having the variables on both sides.",
      " Reducing equation to simpler form",
      " Equation reducible to the linear form.",
    ]
    },
    {
      name: ["3. Understanding Quadrilaterals"], chapters: [
      " Polygons",
      " Types of Polygons",
      " Diagonals",
      " Convex & Concave Polygons",
      " Regular & Irregular Polygons",
      " A.S.A property",
      " Measures of exterior angles of a polygon",
      " Kinds of quadrilaterals",
      " Trapezium",
      " Kite",
      " Parallelogram",
      " Rhombus",
      " Square",
      " Properties",
    ]
    },
    {
      name: ["4. Practical Geometry"], chapters: [
      " Constructing a Quadrilateral",
      " Constructing when 2 diagonals & 3 sides are given",
      " When 3 sides & 2 included angles are given.",
    ]
    },
    {
      name: ["5. Data Handling"], chapters: [
      " Pictograph",
      " Bar Graph",
      " Double bar graph",
      " Organizing data",
      " Grouping data",
      " Bars with difference",
      " Pie chart",
      " Chance & Probability",
      " Equally likely outcomes",
      " Linking chances to probability",
      " Outcomes as events",
      " Chances & outcomes related to real life.",
    ]
    },
    {
      name: ["6. Squares & Square Roots"], chapters: [
      " Adding triangular No’s",
      " Numbers b/w square No’s",
      " Finding the squares of a No.",
      " Pythagorean triplets",
      " Square roots",
      " Square roots by division method",
      " Square roots of decimals",
      " Estimating square root.",
    ]
    },
    {
      name: ["7. Cubes & Cube Roots"], chapters: [
      " Cubes",
      " Adding consecutive odd No’s",
      " Cubes & their prime factors",
      " Smallest multiple i.e., perfect cube",
      " Cube root of a cube Number.",
    ]
    },
    {
      name: ["8. Comparing Quantities"], chapters: [
      " Ratios",
      " Percentages",
      " Increase & Decrease in %",
      " Finding discounts",
      " Finding C.P, S.P, Profit %, Loss %",
      " Sales Tax",
      " Simple Interest",
      " Compound Interest",
      " Rate compounded Annually / Half Yearly / Semi Annually",
      " Applications",
    ]
    },
    {
      name: ["9. Algebric Expressions & Indentities"], chapters: [
      " Expression",
      " Expression on a Number line",
      " Terms",
      " Factors",
      " Coefficients",
      " Monomials",
      " Binomials",
      " Polynomials",
      " Like & Unlike Terms",
      " Addition & Subtraction & Multiplication of Algebric expression.",
      " Multiplying a monomial by a Monomial",
      " Multiplying a monomial by a polynomial ",
      " Identity",
    ]
    },
    {
      name: ["10. Visualising Solid Shapes"], chapters: [
      " View of 2-Dimensional Shapes",
      " View of 3-Dimensional Shapes",
      " Mapping",
      " Faces",
      " Edges",
      " Vertices",
      " Convex polyhedrons",
      " Regular Polyhedrons",
      " Eulers Formula",
    ]
    },
    {
      name: ["11. Mensuration"], chapters: [
      " Shapes ",
      " Area of Trapezium",
      " Area of General Quadrilateral",
      " Area of Polygon",
      " Surface area of cube, cuboid, cylinder",
      " Cylinders",
      " Volumes",
      " Volume & capacity",
    ]
    },
    {
      name: ["12. Exponents & Powers"], chapters: [
      " Powers with negative exponents",
      " Laws of exponents",
      " Use of exponents to express",
      " Small No’s in standard form",
      " Comparing very large & very small No’s",
    ]
    },
    {
      name: ["13. Direct & Inverse Proportions:"], chapters: [
      " Direct proportions",
      " Inverse proportions",
    ]
    },
    {
      name: ["14. Factorization"], chapters: [
      " Factors of Natural No’s",
      " Factors of algebraic expressions",
      " Factorization",
      " Regrouping terms",
      " Factorization using Identities",
      " Factors of the form (x + a) (x + b)",
      " Division of algebraic expressions",
    ]
    },
    {
      name: ["15. Introduction to Graphs"], chapters: [
      " Bar graph",
      " Pie graph",
      " Histogram",
      " Line graph",
      " Co-ordinates",
      " Applications.",
    ]
    },
    {
      name: ["16. Playing with Numbers"], chapters: [
      " Number in General form",
      " Letters for digits",
      " Tests of Divisibility",
    ]
    }];
  public class8science = [
    {
      name: ["1. Crop Production and Management"], chapters: [
      " Agricultural Practices",
      " Basic practice of crop production",
      " Preparation of soil",
      " Sowing",
      " Adding manure and fertilizers",
      " Irrigation",
      " Production of Weeds",
      " Harvesting",
      " Storage",
      " Food From Animals",
    ]
    },
    {
      name: ["2. Microorganisms: Friends and Foe"], chapters: [
      " Microorganisms",
      " Where do microorganisms live?",
      " Microorganisms and Us",
      " Harmful Microorganisms",
      " Food Preservation",
      " Nitrogen Fixation",
      " Nitrogen Cycle",
    ]
    },
    {
      name: ["3. Synthetic Fibers and Plastics"], chapters: [
      " What are synthetic fibers and plastics?",
      " Types of synthetic fibers",
      " Characteristics of synthetic fibers",
      " Plastics",
      " Plastics as materials of choice",
      " Plastics and the environment",
    ]
    },
    {
      name: ["4. Materials: Metals and Non-Metals"], chapters: [
      " Physical properties of metals and Non-metals",
      " Chemical properties of metals and Non-metals",
      " Uses of metals and non-metals",
    ]
    },
    {
      name: ["5. Coal and Petroleum"], chapters: [
      " Coal",
      " Petroleum",
      " Natural Gas",
      " Some Natural resources and limited",
    ]
    },
    {
      name: ["6. Combustion and Flame"], chapters: [
      " What is combustion",
      " How do we control Fire?",
      " Types of combustion",
      " Flame",
      " Structure of Flame",
      " What is a Fuel?",
      " Fuel Efficiency",
    ]
    },
    {
      name: ["7. Conservation of Plants and Animals"], chapters: [
      " Deforestation and it’s causes",
      " Consequences of Deforestation",
      " Conservation of forest and Wild life",
      " Biosphere Reserve",
      " Flora and Fauna",
      " Endemic species",
      " Wildlife Sanctuary",
      " National Park",
      " Red Data Book",
      " Migration",
      " Recycling of paper",
      " Reforestation",
    ]
    },
    {
      name: ["8. Cell Structure and Functions"], chapters: [
      " Discovery of the cell",
      " The cell",
      " Organisms show variety in cell number shape and size",
      " Cell Structure and Function",
      " Parts of the cell",
    ]
    },
    {
      name: ["9. Reproduction in animals"], chapters: [
      " Modes of reproduction",
      " Sexual Reproduction",
      " Asexual Reproduction",
    ]
    },
    {
      name: ["10. Reaching the age of Adolescence"], chapters: [
      " Adolescence and puberty",
      " Changes at Puberty",
      " Sexual Characters",
      " Role of hormones in initiation reproductive function",
      " Reproductive phase of life in humans",
      " How is Sex of baby determined?",
      " Hormones other than sex hormones",
      " Role of hormones in completing the life history of insects and frogs",
      " Reproductive Health",
    ]
    },
    {
      name: ["11. Force and Pressure"], chapters: [
      " Force – A Push or a pull",
      " Exploring Forces",
      " A Force can change the state of motion",
      " Force can change the shape of an object",
      " Contact Forces",
      " Non-Contact forces",
      " Pressure",
      " Pressure exerted by liquids and gases",
      " Atmospheric Pressure",
    ]
    },
    {
      name: ["12. Friction"], chapters: [
      " Force of friction",
      " Factors affecting friction",
      " Friction: A necessary evil",
      " Increasing and reduction friction",
      " Wheels reduce friction",
      " Fluid Friction",
    ]
    },
    {
      name: ["13. Sound"], chapters: [
      " Sound is produced by vibrating body",
      " Sound produced by humans",
      " Sound needs a medium for propagation",
      " We hear sound through our ears",
      " Amplitude, Time period and frequency of a vibration",
      " Audible and Inaudible Sounds",
      " Noise and Music",
      " Noise Pollution",
    ]
    },
    {
      name: ["14. Chemical effects of electric current"], chapters: [
      " Do liquid conduct electric city?",
      " Chemical effects of electric current",
      " Electro Planting",
    ]
    },
    {
      name: ["15. Some natural Phenomena"], chapters: [
      " Lightning",
      " Charging by rubbing",
      " Types of charges and their Interaction",
      " A transfer of charge",
      " The story of lightning",
      " Lightning safety",
      " Earthquakes",
    ]
    },
    {
      name: ["16. Light"], chapters: [
      " What makes things visible",
      " Laws of reflection",
      " Regular and Diffused reflection",
      " Reflected light can be reflected again",
      " Multiple images",
      " Sun light – White or color",
      " What is inside our eyes",
      " Care of the eyes",
      " Visually Impaired persons can read and write",
      " What is the Braille system?",
    ]
    },
    {
      name: ["17. Stars and Solar System"], chapters: [
      " The moon ",
      " The stars",
      " Constellations",
      " The solar system",
      " Some other members of the solar system",
    ]
    },
    {
      name: ["18. Pollution of Air and Water:"], chapters: [
      " Air Pollution",
      " How does air get polluted",
      " Case Study – The Taj Mahal",
      " Greenhouse effect",
      " What can be done?",
      " Water Pollution",
      " How does water get polluted",
      " What is Potable water and How is Water Purified?",
      " What can be done?",
    ]
    }];

  public class9maths = [
    {
      name: ["1. Number system"], chapters: [
      " Irrational numbers",
      " Real numbers and their decimal expansions",
      " Representing real numbers on the number line",
      " Operations on real numbers",
      " Laws of exponents for real numbers",]
    },
    {
      name: ["2. polynomials"], chapters: [
      " polynomials in one variable",
      " zeroes of a polynomial",
      " remainder theorem",
      " factorization of polynomials",
      " algebraic identities",
    ]
    },
    {
      name: ["3. Coordinate geometry"], chapters: [
      " Cartesian system",
      " Plotting a point in the plane if its coordinates are given",
    ]
    },
    {
      name: ["4. Linear equations in two variables"], chapters: [
      " Linear equations",
      " Solution of a linear equation",
      " Graph of a linear equation in two variables",
      " Equation of linear parallel to x-axis and y- axis",
    ]
    },
    {
      name: ["5. Introduction to Euclids Geometry"], chapters: [
      " Euclid’s definition Axioms and postilaes",
      " Equivalent versions of Euclid’s fifth postulate",]
    },
    {
      name: ["6. Lines and Angles"], chapters: [
      " Basic terms and definition",
      " Interescecting lines and nonm intersecting lines",
      " Pairs of angles",
      " Parallel lines and transversal",
      " Lines parallel to the same line",
      " Angle sum property of a triangle",
    ]
    },
    {
      name: ["7. Triangles"], chapters: [
      " Congruence of triangles",
      " Criteria of congruence of triangles",
      " Some properties of a triangle",
      " Some more criteria for congruence of triangles",
      " Inequalities in a triangle",
    ]
    },
    {
      name: ["8. Quadrilaterals"], chapters: [
      " Angle sum property of a quadrilateral",
      " Types of quadrilaterals",
      " Properties of a parallelogram",
      " Another condition for a quadrilateral to be a parallelogram",
      " The midpoint Theorem",
    ]
    },
    {
      name: ["9. Areas of Parallelograms and Triangles"], chapters: [
      " Figures on the same base and between the same parallels",
      " Parallelograms on the same base and between the same parallels",
      " Triangles on the same base and between the same parallels",
    ]
    },


    {
      name: ["10. Circles"], chapters: [
      " Circles and its related terms :A Review",
      " Angle subtended by a chord at a point",
      " Perpendicular from the center to a chord",
      " Circle through three points",
      " Equal chords and their distances from the center",
      " Angle subtended by an Arc of a circle",
      " Cyclic Quadrilaterals",
    ]
    },

    {
      name: ["11. Construction"], chapters: [
      " Basic constructions ",
      " Some constructions of triangles",
    ]
    }, {
      name: ["12. HERON’S FORMULAE"], chapters: [
        " Area of a Triangle – by Heron’s formula",
        " Application of Heron’s formula in finding areas of quadrilaterals",
      ]
    },
    {
      name: ["13. Surface Areas and Volume:"], chapters: [
      " Surface area of a cuboid and a cube",
      " Surface area of a right circular cylinder",
      " Surface area of a right circular cone",
      " Surface area of a sphere",
      " Volume of a cuboid",
      " Volume of a cylinder",
      " Volume of Right circular cone",
      " Volume of a sphere",
    ]
    },
    {
      name: ["14. Statistics"], chapters: [
      " Collection of Data",
      " Presentation of data",
      " Geographical representation of data",
      " Measures of central tendency",
    ]
    },
    {
      name: ["15. Probability"], chapters: [
      " Probability- an Experimental Approach",
    ]
    }
  ];
  public class9science = [
    {
      name: ["1. Matter In our surroundings"], chapters: [
      " Physical nature of matter",
      " Characteristics of particles of matter",
      " States of matter",
      " Can matter change its state",
      " Evaporation",
    ]
    },
    {
      name: ["2. Is matter around us Pure"], chapters: [
      " What is mixture",
      " What is solution",
      " Separating The Components Of A Mixture",
      " Physical And Chemical Changes",
      " What Are The Types Of Pure Substances",
    ]
    },
    {
      name: ["3. Atoms And Molecules"], chapters: [
      " Laws Of Chemical Combination",
      " What is The Atom",
      " What Is the Molecule",
      " Writing Chemical Formulae",
      " Molecular Mass And Mole Concept",
    ]
    },
    {
      name: ["4. Structure Of The Atom"], chapters: [
      " Charged Particles In Matter",
      " The Structure Of An Atom",
      " How Are Electrons Distributed In Different Orbits(Shells)",
      " Valency",
      " Atomic Number And Mass Number",
      " Isotopes",
    ]
    },
    {
      name: ["5. The Fundamental Unit Of Life"], chapters: [
      " What Are Living Things Made Up Of",
      " What Is Cell Made Up Of? What Is The Structural Organisation Of A cell",
    ]
    },
    {
      name: ["6. Tissues"], chapters: [
      " Are plants and animals made of same types of tissues",
      " Plant tissues",
      " Animal tissues",
    ]
    },
    {
      name: ["7. Diversity in living organisms"], chapters: [
      " What is the basic of classification",
      " Classification and evolution",
      " The hierarchy of classification groups",
      " Plantae",
      " Animalia",
      " Nomenclature",
    ]
    },
    {
      name: ["8. Motion"], chapters: [
      " Describing motion",
      " Measuring the rate of motion",
      " Rate of change of velocity",
      " Graphical representation of motion",
      " Equations of motions by graphical method",
    ]
    },
    {
      name: ["9. Force and law of motions"], chapters: [
      " Balanced and unbalanced forces",
      " First law of motion",
      " Inertia and mass",
      " Second law of motion",
      " Third law of motion",
      " Conservation of Momentum",
    ]
    },
    {
      name: [" 10. Gravitation"], chapters: [

      " Gravitation",
      " Free fall",
      " Mass",
      " Weight",
      " Thrust and Pressure",
      " Archimedes Principle",
    ]
    },
    {
      name: ["11. Work and energy"], chapters: [

      " Work",
      " Energy",
      " Rate of doing work",
    ]
    },
    {
      name: [
        "12. Sound"], chapters: [

      " Production of sound",
      " Propagation of sound",
      " Reflection of sound",
      " Range of hearing",
      " Applications of ultra sound",
      " Structure of human ear",
    ]
    },
    {
      name: [
        "13. Why do we fall ill"], chapters: [

      " Health and its failure",
      " Disease and its causes",
      " Infectious Diseases",
    ]
    },
    {
      name: [
        "14. Natural Resources"], chapters: [

      " The breath of life",
      " Water :A wonder liquid",
      " Mineral riches in the soil",
      " Biogeochemical cycles",
      " Ozone layer",
    ]
    },
    {
      name: [
        "15. Improvement in Food Resources"], chapters: [

      " Improvement in crop yields",
      " Animal husbandry",
    ]
    },

  ];


  public classtenmaths = [
    {
      name: ["1. Real Numbers"],
      chapters: [
        "Euclid’s Divisional Lemma",
        "The Fundamental Theorem of Arithmetic",
        "Revisiting Irrational Numbers",
        "Revisiting Rational Numbers and their Decimal Expansions",
      ]
    },
    {
      name: ["2. Polynomials"],
      chapters: [
        "Geometrical Meaning of the Zeroes of a Polynomial",
        "Relationship between Zeroes and Coefficient of a Polynomial",
        "Division Algorithm for Polynomials",
      ]
    },
    {
      name: ["3. Pair of Linear Equations in Two Variables"],
      chapters: [
        "Pair of Linear Equations in Two Variables",
        "Graphical Method of Solution of a Pair of Linear Equations ",
        "Substitution Method",
        "Elimination Method",
        "Cross-Multiplication Method",
        "Equation Reducible to a pair of Linear Equation in Two Variables",
      ]
    },
    {
      name: ["4. Quadratic Equations"],
      chapters: [
        "Quadratic Equations",
        "Solution of a Quadratic Equation by Factorisation",
        "Solution of a Quadratic Equation by completing the square",
        "Nature of roots",
      ]
    },
    {
      name: ["5. Arithmetic Progressions"],
      chapters: [
        "Arithmetic Progressions",
        "Nth Term of an AP",
        "Sum of first n Terms of an AP",
      ]
    },
    {
      name: ["6. Triangles"],
      chapters: [
        "Similar Figures",
        "Similarity of Triangles",
        "Criteria for similarity of Triangles",
        "Areas of similar Triangles",
        "Pythagoras Theorem",
      ]
    },
    {
      name: ["7. Coordinate Geometry"],
      chapters: [
        "Distance Formula ",
        "Section Formula",
        "Area of a Triangle",
      ]
    },
    {
      name: ["8. Introduction to Trigonometry"],
      chapters: [
        "Trigonometric Ratios",
        "Trigonometric Ratios of some specific Angles",
        "Trigonometric Ratios of Complementary Angles",
        "Trigonometric Identities",
      ]
    },
    {
      name: ["9. Some Application of Trigonometry"],
      chapters: [
        "Heights and Distances",
      ]
    },
    {
      name: ["10. Circles"],
      chapters: [
        "Tangent to a Circle",
        "Number of Tangents from a point on a circle",
      ]
    },
    {
      name: ["11. Constructions"],
      chapters: [
        "Division of a Line Segment",
        "Construction of Tangents to a circle",
      ]
    },
    {
      name: ["12. Areas Related to Circles"],
      chapters: [
        "Perimeter and Area of a Circle – A Review",
        "Areas of Sector and Segment of a Circle",
        "Areas of Combinations of Plane Figures",
      ]
    },
    {
      name: ["13. Surface Areas and Volumes"],
      chapters: [
        "Surface Area of a Combination of Solids",
        "Volume of a Combination of Solids",
        "Conversion of Solid from One shape to Another",
        "Frustum of a Cone",
      ]
    },
    {
      name: ["14. Statistics"],
      chapters: [
        "Mean of Grouped Data",
        "Mode of Grouped Data",
        "Median of Grouped Data",
        "Graphical Representation of Cumulative Frequency Distribution",
      ]
    },
    {
      name: ["15. Probability"],
      chapters: [
        "Probability – A Theoretical Approach",
      ]
    },


  ];
  public classtenscience = [
    {
      name: ["1. Chemical Reactions and Equations"],
      chapters: [
        "Chemical Equations",
        "Types of Chemical Equations",
        "Have You Observed The Effects Of Oxidation Reaction In Every Day Life",
      ]
    },
    {
      name: ["2. Acids, Bases and Salts "],
      chapters: [
        "Understanding The chemical Properties Of Acids and Bases",
        "What Do All Acids And Bases Have In Common",
        "How Strong Are Acid Or Base Solutions",
        "More About Salts",
      ]
    },
    {
      name: ["3. Metals And Non – Metals"],
      chapters: [
        "Physical Properties ",
        "Chemical Properties and Metals",
        "How Do Metals And Non – Metals React",
        "Occurrence Of Metals",
        "Corrosion",
      ]
    },
    {
      name: ["4. Carbon And Its Compounds"],
      chapters: [
        "Bonding In Carbon – The Covalent Bond",
        "Versatile Nature Of Carbon",
        "Chemical Properties OF Carbon Compounds",
        "Some Important Carbon Compounds – Ethanol And Ethanoic Acid",
        "Soaps And Detergents ",
      ]
    },
    {
      name: ["5. Periodic Classification Of Elements"],
      chapters: [
        "Making Order Out Of Chaos – Early Attempts At The Classification Of Elements",
        "Making Order Out Of Chaos – Mendeleev’s periodic table",
        "Making Order Out Of Chaos-the modern periodic table",
      ]
    },
    {
      name: ["6. Life Processes"],
      chapters: [
        " What Are Life Processes",
        " Nutrition",
        " Respiration",
        " Transportation",
        " Excretion",
      ]
    },
    {
      name: ["7. Control And Coordination"],
      chapters: [
        " Animals – Nervous System",
        " Coordination In Plants",
        " Hormones In Animals",
      ]
    },
    {
      name: ["8. How To Organisms Reproduce"],
      chapters: [
        " Do Organisms Create Exact Copies Of Themselves",
        " Modes Of Reproduction Used By Single Organisms",
        " Sexual Reproduction",
      ]
    },
    {
      name: ["9. Heredity And Evolution "],
      chapters: [
        " Accumulation Of Variation During Reproduction",
        " Heredity",
        " Evolution",
        " Speciation",
        " Evolution And Classification",

        " Evolution Should Not Be Equated With Progress",
      ]
    },
    {
      name: ["10. Light Reflection And Refraction"],
      chapters: [
        " Reflection Of Light",
        " Spherical Mirrors",
        " Refraction Of Light",
      ]
    },
    {
      name: ["11. The Human Eye And Colourful World"],
      chapters: [
        " The Human Eye",
        " Defects Of Vision And Their Correction",
        " Refraction Of Light Through A Prism",
        " Dispersion Of White Light by A Glass Prism",
        " Atmospheric Refraction",
        " Scattering Of Light",
      ]
    },
    {
      name: ["12. Electricity "],
      chapters: [
        " Electric Current And Circuit",
        " Electric Potential And Potential Difference ",
        " Circuit Diagram",
        " OHM’S law",
        " Factors On Which The Resistance Of A Conductor Depends",
        " Resistance Of System Of Resistors",
        " Heating Effect Of Electric Current",
        " Electric Power",
      ]
    },
    {
      name: ["13. Magnetic Effects Of Electric Current"],
      chapters: [
        " Magnetic Field and Field Lines",
        " Magnetic Field Due To A Current – Carrying Conductor",
        " Force On A Current - Carrying Conductor In A Magnetic Field",
        " Electric Motor",
        " Electromagnetic Induction",
        " Electric Generator",
        " Domestic Electric Circuits",
      ]
    },
    {
      name: ["14. Sources Of Energy"],
      chapters: [
        " What Is A Good Sources Of Energy ",
        " Conventional Sources Of Energy",
        " Alternative Or Non - Conventional Sources Of Energy",
        " Environmental Consequences",
        " How Ling Will An Energy Source Last Us",
      ]
    },
    {
      name: ["15. Our Environment"],
      chapters: [
        " What Happens When We Add Our Waste To The Environment",
        " Eco – System – What Are Its Components",
        " How Do Our Activities Affect The Environment",
      ]
    },
    {
      name: ["16. Management Of Natural Resources"],
      chapters: [
        " Why Do We Need To Manage Our Resources",
        " Forests And Wild Life",
        " Water for all",
        " Coal and petroleum",
        " An overview of Natural Resources Management",
      ]
    },];

  public selectedclassroom: any = {
    classname: "",
    classintro: "",
    classfeature: "",
    classsysmaths: "",
    classsysscience: ""
  };

  constructor (private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((paramsId: any) => {
      if (paramsId.id == 'six' ||paramsId.id == '6') {
        this.selectedclassroom.classname = this.classroom6;
        this.selectedclassroom.classintro = this.class6intro;
        this.selectedclassroom.classfeature = this.class6feature;
        this.selectedclassroom.classsysmaths = this.class6maths;
        this.selectedclassroom.classsysscience = this.class6science;

      }
      if (paramsId.id == 'seven' || paramsId.id == '7') {
        this.selectedclassroom.classname = this.classroom7;
        this.selectedclassroom.classintro = this.class7intro;
        this.selectedclassroom.classfeature = this.class7feature;
        this.selectedclassroom.classsysmaths = this.class7maths;
        this.selectedclassroom.classsysscience = this.class7science;
      }
      if (paramsId.id == 'eight' || paramsId.id == '8') {
        this.selectedclassroom.classname = this.classroom8;
        this.selectedclassroom.classintro = this.class8intro;
        this.selectedclassroom.classfeature = this.class8feature;
        this.selectedclassroom.classsysmaths = this.class8maths;
        this.selectedclassroom.classsysscience = this.class8science;
      }
      if (paramsId.id == 'nine' || paramsId.id == '9') {
        this.selectedclassroom.classname = this.classroom9;
        this.selectedclassroom.classintro = this.class9intro;
        this.selectedclassroom.classfeature = this.class9feature;
        this.selectedclassroom.classsysmaths = this.class9maths;
        this.selectedclassroom.classsysscience = this.class9science;
      }
      if (paramsId.id == 'ten' || paramsId.id == '10') {
        this.selectedclassroom.classname = this.classroom10;
        this.selectedclassroom.classintro = this.class10intro;
        this.selectedclassroom.classfeature = this.class10feature;
        this.selectedclassroom.classsysmaths = this.classtenmaths;
        this.selectedclassroom.classsysscience = this.classtenscience;
      } if (paramsId.id == 'scorewell' || paramsId.id == 'scorewell') {
        this.selectedclassroom.classname = this.ScoreWell;
        this.selectedclassroom.classintro = this.ScoreWellintro;
        this.selectedclassroom.classfeature = this.scorewellfeature;
        this.selectedclassroom.classsysmaths = this.classtenmaths;
        this.selectedclassroom.classsysscience = this.classtenscience;
      }
    
    });

  }

  ngAfterViewInit () {
    $('.yostrong').css("color", '#12327C');
  }



  ngOnInit () {
    $(".navbarheader").removeClass("bggreen1");

    // $(".mainNav").css("background","#27841D");

  }
  public sciencesyl () {
    
    var elmnt = document.getElementById("maths1");

    $('html,body').animate({
        scrollTop: $("#foun1").offset().top-100
      },
      'slow');
  }

  public mathssyl () {
    var elmnt = document.getElementById("foun2");
    
    $('html,body').animate({
      scrollTop: $("#foun2").offset().top-100
    },
    'slow');
  }


}
