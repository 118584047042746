import { Component, OnInit } from '@angular/core';
import { UserService } from "../user.service";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-paymentchek',
  templateUrl: './paymentchek.component.html',
  styleUrls: ['./paymentchek.component.css']
})
export class PaymentchekComponent implements OnInit {
  productdetails: any = '';
  public InvoiceNumber: any;
  public Invoicedata: any = false;
  public order_status='' 

  constructor(private userservice: UserService, private router: ActivatedRoute,private toastr: ToastrService) {
  }

  ngOnInit() {

    
    
    $(".navbarheader").addClass("bggreen1");
    this.router.params.subscribe(data => {

      this.InvoiceNumber = data.id;

    }, () => {

    }, () => {

    });

    const jsondata = {
      order_id: this.InvoiceNumber,
      parentAccountId: this.InvoiceNumber.substring(0, 10)
    };
    window.scrollTo(0, 0);
    this.userservice.billingdetails(jsondata).subscribe(data => {
      this.Invoicedata = data[0];

    }, () => {

    }, () => {
      var jsondata1 = {

        "subscriptionId": sessionStorage.getItem('subid')

      };
      this.userservice.getallproductsbyid(jsondata1).subscribe((data) => {


        this.productdetails = data;
        // var amt=(parseInt(this.productdetails.price)*18)/100;
        // this.productdetails.price1=(this.productdetails.price-amt).toFixed(2);
      })
      const jsondata = {

        accountId: this.InvoiceNumber.substring(0, 10)
      };
      // console.log(this.Invoicedata.order_status)
      if (this.Invoicedata.order_status == "Success" || this.Invoicedata.order_status == "Awaited") {
        this.toastr.success("Student registration will be activated and start attending the classes after 24hrs");
       var self1=this;
        this.order_status = 'Success1';
        setTimeout(function () {
          window.scrollTo(0, 0);
          self1.order_status = 'Success';
        }, 2000);
        this.userservice.updatetoparent(jsondata).subscribe(data => {
        }, () => {

        }, () => {
          const jsondata = {
            sAccountId: sessionStorage.getItem("sAccountId"),
            subsTypeId: sessionStorage.getItem("subid")
          };
          this.userservice.setsubit(jsondata).subscribe(data => {
          }, () => {

          }, () => {
            const jsondata = {

              parentAccountId: sessionStorage.getItem("paccountId"),
              studentAccountId: sessionStorage.getItem("sAccountId"),
              orderId: this.InvoiceNumber
            };


            this.userservice.allotPackageToStudent(jsondata).subscribe((data: any) => {
            });
          })
        })
      }else{
        this.Invoicedata["order_status"] ="unSuccess";
       
      }
    })

  }
  printDiv() {
    var printContents = document.getElementById('GFG').innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    // console.log(printContents)

    window.print();

    document.body.innerHTML = originalContents;
  }


}
