import { Component, OnInit } from '@angular/core';
import {UserService} from "../user.service";

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(private userservice: UserService) { }
  public selected = "6";
  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  Number1 = "\\+?\\d[\\d -]{3,12}\\d";

  ngOnInit() {
  }
  register (val) {
    var json ={
      "email":val.primaryEmail,
      "phoneNumber":val.Number1
    }
    this.userservice.guestregister(json).subscribe(data => {

    }, () => {

    }, () => {
      window.open("https://app.yolearn.com/#/Guest/" + val.radiomodel + "/recordedGrid", "_self");

    });
  }

}
