import { Component ,OnInit} from '@angular/core';
import { WOW } from 'wowjs/dist/wow.min';
import * as $ from 'jquery';
import {NavigationEnd, Router} from "@angular/router";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  title = 'yolearn';
  constructor(private router: Router) { }
  public chatform=true;

  ngOnInit() {

  var  self=this;
    $(document).ready(function() {

      var $chatbox = $('.chatbox'),
        $chatboxTitle = $('.chatbox__title'),
        $chatboxTitleClose = $('.chatbox__title__close'),
        $chatboxCredentials = $('.chatbox__credentials');
      $chatboxTitle.on('click', function() {

        $chatbox.toggleClass('chatbox--tray');
        $chatbox.addClass('chatbox--empty');
        $chatbox.removeClass('chatbox--start');
        self.chatform=true;
      });
      $chatboxTitleClose.on('click', function(e) {
        e.stopPropagation();
        $chatbox.addClass('chatbox--closed');
      });
      $chatbox.on('transitionend', function() {
        if ($chatbox.hasClass('chatbox--closed')) $chatbox.remove();
      });
      $chatboxCredentials.on('submit', function(e) {
        e.preventDefault();
        $chatbox.removeClass('chatbox--empty');
        $chatbox.addClass('chatbox--start');
        self.chatform=false;
      });
    });
    // new WOW().init();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  movetotop(){
    $('body,html').animate({
      scrollTop : 0                       // Scroll to top of body
    }, 500);
  };

}
